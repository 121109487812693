export const ErrorMessage = {
  meetingFinished:
    " Your Meeting Has Already Finished, Please Create a New Meeting ",
  meetingNotStarted: " Your Meeting Has Not Started Yet ** ",
  NotConnecting:
    " Some Problem in connecting Meeting, please Contact an Administrator ",
  NotAuthorized: " You are not authorized to join the meeting ",
  Rejected: " Sorry, Only Registered User can join ",
  ApprovaCancleled: "Sorry, Moderator Have Cancelled your Request ",
  WaitingForApproval: "  Please wait, Moderator needs to approve your Request ",
  NoMeetingAvailiable:
    "There is No Meeting Availiable for this Link, Please Create a New Meeting",
  WaitingForModeratorJoin: "Please wait, Moderator needs to join",
};

export const MessageContants = {
  SingleUser: "Someone wants to join this call",
  MultipleUser: " Multiple User Wants to Joint this Call",
};
export const AppURLs = {};

export const warnings = {
  title: "Be Sure you Know who this is",
  content: "Hello",
};

export const RequestStatus = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  OPTIONS: "OPTIONS",
};

export const demoParticipantList = [
  {
    attenderId: "1",
    username: "muthu",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "2",
    username: "Raja",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "3",
    username: "sive",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "4",
    username: "ganesh",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "5",
    username: "mani",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "6",
    username: "rahim",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "7",
    username: "sam",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "8",
    username: "tamil",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "9",
    username: "gokul",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "10",
    username: "arun",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "11",
    username: "kamal",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "1",
    username: "muthu",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "2",
    username: "Raja",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "1",
    username: "muthu",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "2",
    username: "Raja",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "1",
    username: "muthu",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "2",
    username: "Raja",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "1",
    username: "muthu",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "2",
    username: "Raja",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "1",
    username: "muthu",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "2",
    username: "Raja",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "1",
    username: "muthu",
    userId: "123",
    meetingId: "1",
  },
  {
    attenderId: "2",
    username: "Raja",
    userId: "123",
    meetingId: "1",
  },
];
