import { useEffect, useMemo, useState } from "react";
import { IoMicSharp, IoMicOffSharp } from "react-icons/io5";
import { useVolumeLevel, VolumeIndicator } from "react-volume-indicator";

function App({ audioStatus, updatePermission, status, permissionStatus }) {
  const [permission, setPermission] = useState("");
  const [startRecording, stopRecording, volume] = useVolumeLevel();

  useEffect(() => {
    const startRecordingAction = async () => {
      const message =
        "Microphone access is required to use the volume indicator. Please allow access in your browser settings or try again.";
      try {
        await navigator.mediaDevices
          .getUserMedia({
            audio: true,
            // video: false,
          })
          .then((data) => {
            setPermission("granted");
            console.log("permission-given", data);
            startRecording();
            updatePermission("audioPermission", "granted", status, false);
          })
          .catch((e) => {
            console.log("permission-not-given", e);
            setPermission("error");
            updatePermission("audioPermission", "error", status, true);
            alert(message);
          });
      } catch (e) {
        console.log("permission-error", e);
        updatePermission("audioPermission", "error", status, true);
        setPermission("error");

        alert(message);
      }
    };
    // const startRecordingAction = () => {
    //   const message =
    //     "Microphone access is required to use the volume indicator. Please allow access in your browser settings or try again.";

    //   navigator.permissions
    //     .query({ name: "microphone" })
    //     .then((result) => {
    //       console.log("result", result);
    //       if (result.state === "denied") {
    //         // User has previously denied access
    //         // alert(message);
    //         setPermission("error");
    //         updatePermission("audioPermission", "error", status, true);
    //       } else if (result.state === "granted" || result.state === "prompt") {
    //         // User has previously granted access, but might have revoked it in settings
    //         setPermission("granted");
    //         startRecording();
    //         updatePermission("audioPermission", "granted", status, false);
    //       } else {
    //         // alert(message);
    //         setPermission("error");
    //         updatePermission("audioPermission", "error", status, true);
    //       }
    //     })
    //     .catch((error) => {
    //       updatePermission("audioPermission", "error");
    //       updatePermission("permissionWarn", true);
    //       console.error("Error checking microphone permission:", error);
    //     });
    // };
    // console.log("volume", volume);
    if (audioStatus) {
      startRecordingAction();
    } else if (volume) {
      stopRecording();
    }
  }, [audioStatus]);

  return (
    <div style={{ width: "9rem", position: "relative", height: "100%" }}>
      <div className="mic-container">
        <VolumeIndicator
          backgroundColor="gainsboro"
          indicatorColor="green"
          volume={volume}
        />
        {permission === "granted" && audioStatus ? (
          <IoMicSharp
            className="detector-mic"
            style={{
              color: "green",
            }}
          />
        ) : (
          <IoMicOffSharp
            className="detector-mic"
            style={{
              color: permissionStatus === "granted" ? "green" : "red",
            }}
          />
        )}

        {/* <button
        onClick={startRecordingAction}
        style={{ backgroundColor: "red", padding: 10, margin: 10 }}
      >
        Record
      </button>
      <button
        onClick={stopRecording}
        style={{ backgroundColor: "green", padding: 10 }}
      >
        Stop
      </button> */}
      </div>
      <p
        className="mic-warning"
        style={{ color: permission === "granted" ? "green" : "red" }}
      >
        {permission === "granted" ? "Mic is working" : "Mic access is required"}
      </p>
    </div>
  );
}

export default App;
