// TESTING
// export const CHAT_URL = "https://chat.yuukke.betalearnings.com/";
// export const API_URL = "https://bml.betalearnings.com/bca/";

// LOCAL
// export const CHAT_URL = "http://localhost:5000/";
// export const API_URL = "http://localhost:8081/";

// PROD
export const CHAT_URL = "https://chatv2.yuukke.com/";
export const API_URL = "https://corporate.yuukke.com/bcav2/";
