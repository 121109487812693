import React from "react";
import JistiComponent from "./JitsiComponent";
import NoMatchcomponent from "./NoMatchcomponent";
import MeetingFinished from "./MeetingFinished";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <div
      style={{ posistion: "relative", height: "100vh", background: "black" }}
    >
      <Router>
        <Switch>
          <Route exact path="/">
            <JistiComponent />
          </Route>
          <Route exat path="/Left">
            <MeetingFinished />
          </Route>
          <Route path="*">
            <NoMatchcomponent />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
