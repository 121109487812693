import React, { useEffect } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function NoMatchcomponent() {
  console.log("ULR", window.location.href);
  const query = useQuery();
  const location = useLocation();

  const authenticated = query.get("authenticated");
  const userId = query.get("userId");
  const username = query.get("username");
  const friend = query.get("friend") ?? "undefined";
  const meetingLink = location.pathname;
  const isGroup = query.get("isGroup") ?? "false";
  const loginStatus = query.get("authenticated") == "true" ? true : false;
  const isGlob = query.get("isGlob") ?? "false";
  const yLogin = false;
  const isSpeaker = query.get("sp") == "yes" ? "yes" : "no";
  const userDetails = {
    authenticated,
    // userId,
    // username,
    friend,
    meetingLink,
    isGroup,
    loginStatus,
    yLogin,
    isGlob,
    isSpeaker,
  };
  useEffect(() => {
    console.log("query", userDetails);
    sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
  }, []);
  return (
    <div>
      <Redirect
        to={{
          pathname: "/",
          state: { userDetails },
        }}
      />
    </div>
  );
}

export default NoMatchcomponent;
