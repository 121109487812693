import React, { useEffect, useState } from "react";
import { JitsiMeeting, JitsiMeetAPI } from "@jitsi/react-sdk";
import { API_URL, CHAT_URL } from "../../config";
import { useHistory } from "react-router-dom";
import {
  ErrorMessage,
  MessageContants,
  RequestStatus,
  warnings,
} from "../constants";
import Error from "../Error/Error";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Slide,
  ListItemAvatar,
  ListItemSecondaryAction,
} from "@material-ui/core";
import Sidebar from "../Sidebar/Sidebar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from "@material-ui/core/ListItemText";
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { RxHamburgerMenu, RxCrossCircled } from "react-icons/rx";
import { GrRefresh, GrStatusWarning } from "react-icons/gr";
import Checkbox from "@material-ui/core/Checkbox";
import { demoParticipantList } from "../constants";
// import { Slide } from "@material-ui/core";
import axios from "axios";
import { useInterval } from "../../hooks/useInterval";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import io from "socket.io-client";
import Options from "./Options";
import PreviewPopup from "./PreviewPopup";

// let jitsiId = null;
const useStyles = makeStyles({
  list: {
    width: 500,
  },
  fullList: {
    width: "auto",
  },

  avatar: {
    flex: 1,
    height: "64px",
    width: "64px",

    "&__grouped": {
      height: "64px",
      width: "64px",
    },

    "@media(minWidth: 760px)": {
      height: "64px",
      width: "64px",
    },
  },

  dialog: {
    position: "absolute !important",
    top: 4,
  },
});

const useStylesUtils = makeStyles({
  text: {
    margin: 0,
    fontWeight: "bold",
    fontSize: "14px",
  },

  subText: {
    margin: 0,
    fontSize: "medium",
  },
});

const useStylesWarning = makeStyles({
  warning: {
    background: "#97D2CE",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    // justifyContent:"center",
    gap: "0.5rem",
    padding: "1rem",

    "&__content": {
      flex: "1",
      fontSize: "bold",
      display: "flex",
      flexDirection: "column",
      gap: "0.8rem",
      // padding:"1rem",
      paddingInline: "1rem",
    },
  },
});

const Warning = ({ participantList = [] }) => {
  const classes = useStylesWarning();
  const utilsClasses = useStylesUtils();

  return (
    <div className={classes.warning}>
      <GrStatusWarning
        style={{
          width: "32px",
          height: "32px",
          color: "white",
        }}
      />
      <div className={`${classes.warning}__content`}>
        {participantList?.length > 0 ? (
          <p className={`${utilsClasses.text}`}>
            {participantList?.length} persons might not be whom they claim to be
          </p>
        ) : (
          <p className={`${utilsClasses.text}`}>{warnings["title"]}</p>
        )}
      </div>
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function Meeting({
  attenderDetails: attendeDetails,
  roomName,
  userName = "Fellow Users",
  meetingDetails,
  setErrorMessage,
  details,
  moderatorNeed,
}) {
  const apiRef = React.useRef();
  const history = useHistory();
  const classes = useStyles();
  const utilsClasses = useStylesUtils();
  const [logItems, updateLog] = React.useState([]);
  const [showNew, toggleShowNew] = React.useState(false);
  const [knockingParticipants, updateKnockingParticipants] = React.useState([]);
  const [isModerator, setIsModerator] = React.useState(false);
  const [requestStatus, setRequestStatus] = React.useState(
    RequestStatus["PENDING"]
  );
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [checkSelected, setCheckSelected] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [participantRequestedListCount, setParticipantRequestedListCount] =
    React.useState(0);
  const [participantRequestedList, setParticipantRequestedList] =
    React.useState([]);
  const [attenderDetails, setAttenderDetails] = React.useState(null);
  const [needUseInterval, setNeedUseInterval] = React.useState(false);
  const [isAttendeeAccepted, setAttendeAccepted] = React.useState(false);
  const [showParticipantList, setShowParticipantList] = React.useState(false);
  const socket = React.useRef();
  const [optionsPopupNeed, setOptionScreen] = useState(true);
  const [userPreferences, setUserPreferences] = useState("");
  const [previewModel, setPreviewPopUp] = useState(false);
  useInterval(
    async () => {
      const meetingUSerStatusUrl = `${CHAT_URL}api/meetingUserStatus/${attenderDetails.attendDetails.attendId}`;
      // const meetingUSerStatusParams  = {
      //     attendId : attenderDetails.attendDetails.attendId
      // }
      const request = await axios.get(meetingUSerStatusUrl);

      if (request.data.data?.status) {
        console.log("Meeting user accept status :", request.data.data.status);
        console.log(
          "Meeting user accept status :",
          typeof request.data.data.status
        );
        console.log("Meeting user accept status :", RequestStatus);
        console.log(
          "Meeting user accept status :",
          RequestStatus[request.data.data.status]
        );
        // debugger;
        setRequestStatus(RequestStatus[request.data.data.status]);
        request.data.data.status === "ACCEPTED" && setAttendeAccepted(true);
      }
    },
    isAttendeeAccepted ? null : 5000
  );

  useInterval(
    async () => {
      const getParticipantListURL = `${CHAT_URL}api/meetingAttenderList/${attenderDetails.meetingId}`;
      const request = await axios.get(getParticipantListURL);

      console.log(" GET EXTRA PARTICIPANT LIST", request.data.data);

      if (request.data?.data?.participantList) {
        setParticipantRequestedListCount(
          request.data?.data?.participantList?.length
        );
        setParticipantRequestedList([...request.data?.data?.participantList]);
      }
    },
    isModerator && moderatorNeed ? 5000 : null
  );

  useEffect(() => {
    //Socket Connection
    if (isModerator && attenderDetails?.meetingId) {
      socket.current = io.connect(CHAT_URL, {});
      console.log("Moderator Connected in socket is success");
      socket.current.emit("join_room", attenderDetails.meetingId);
      return () => {
        socket.current.disconnect();
        console.log("Moderator Connected to socket Fail");
      };
    }
  }, [isModerator]);

  useEffect(() => {
    if (isModerator) {
      // Listen for the "updated-list" event
      socket.current.on("updated-list", (data) => {
        console.log("Received updated list from server:", data);
        const moderatedUserList = data?.attendId ? data.attendId : [];
        const filterList = participantRequestedList.filter(
          (item) => !moderatedUserList.includes(item.attendId)
        );
        let count = filterList?.length;
        console.log("Received updated list:", filterList);
        console.log("Received updated count:----", count);

        setParticipantRequestedListCount(count ? count : 0);

        setParticipantRequestedList(filterList ? filterList : filterList);
      });

      return () => {
        socket.current.off("updated-list");
      };
    }
  }, [isModerator, socket, participantRequestedList]);

  useEffect(() => {
    if (!moderatorNeed && isModerator) {
      setUserPreferences("both");
      setOptionScreen(false);
    }
  }, [isModerator, moderatorNeed]);
  const handleAllAcceptRequest = (event) => {
    setSelectAll(!selectAll);
    if (selectAll) {
      setCheckSelected([]);
    } else {
      setCheckSelected(participantRequestedList.map((user) => user.attendId));
    }
  };

  const handleAcceptRequest = (event) => {
    console.log(event.target.id);
    const { id, checked } = event.target;
    console.log(id);
    console.log();
    console.log(` Event List ${id} is ${checked}`);
    if (checked) {
      setCheckSelected([...checkSelected, +id]);
    } else {
      setCheckSelected(checkSelected.filter((item) => item !== +id));
    }
  };

  const handleAcceptedRequestList = async (userList, type) => {
    console.log("userList", userList);
    const url = `${CHAT_URL}api/meetingAcceptRejectStatus`;

    const participantsList = userList.map(({ attendId }) => ({
      attendId: +attendId,
      status: "ACCEPTED",
    }));
    console.log("Participant List", participantsList);

    const params = {
      type: "list",
      participantsList: participantsList,
    };
    console.log(participantsList);
    const request = await axios.post(url, params);

    //WebSocket request
    if (request.data.data?.entry === "finished") {
      const statusId = userList.map((item) => item.attendId);
      changUserStatus(statusId);
    }

    console.log("Request Data :", request.data.data);
    if (
      type === "single" &&
      request.data.data?.entry === "finished" &&
      userList?.length === 1
    ) {
      const filterList = participantRequestedList.filter(
        (item) => item.attendId !== userList[0]?.attendId
      );
      setParticipantRequestedListCount(participantRequestedListCount - 1);

      setParticipantRequestedList(filterList);
    } else if (request.data.data?.entry === "finished") {
      setParticipantRequestedList([]);
      setParticipantRequestedListCount(0);
    }
  };
  const changUserStatus = (statusId) => {
    console.log("socket-request", statusId);
    let list = {
      meetingId: attenderDetails.meetingId,
      attendId: statusId,
    };
    socket.current.emit("change-status", list);
  };
  const handleRejectedRequestList = async (userList, type) => {
    //Single User entry delete
    if (type === "single") {
      console.log(userList);
      const url = `${CHAT_URL}api/meetingAcceptRejectStatus`;
      const participantsList = {
        attendId: userList.attendId,
        status: "REJECTED",
      };
      console.log("Participant List", participantsList);

      const params = {
        type: "list",
        participantsList: [participantsList],
      };
      const request = await axios.post(url, params);
      console.log("Request Data :", request.data.data);

      if (request.data.data?.entry === "finished" && userList.attendId) {
        changUserStatus([+userList.attendId]);

        const filterList = participantRequestedList.filter(
          (item) => item.attendId !== userList.attendId
        );
        console.log("filterList", filterList);

        setParticipantRequestedList(filterList ? filterList : []);
        setParticipantRequestedListCount(filterList ? filterList?.length : 0);
      }
    } else {
      //Multi User entry delete
      console.log(userList);
      const url = `${CHAT_URL}api/meetingAcceptRejectStatus`;

      const participantsList = userList.map(({ attendId }) => ({
        attendId: +attendId,
        status: "REJECTED",
      }));
      console.log("Participant List", participantsList);

      const params = {
        type: "list",
        participantsList: participantsList,
      };
      const request = await axios.post(url, params);
      console.log("Request Data :", request.data.data);

      if (request.data.data?.entry === "finished") {
        const statusId = userList.map((item) => item.attendId);
        changUserStatus(statusId);
        setParticipantRequestedList([]);
        setParticipantRequestedListCount(0);
      }
    }
  };

  const handleAcceptedRequest = async (acceptedUser) => {
    console.log("userList", acceptedUser);

    const url = `${CHAT_URL}api/meetingAcceptRejectStatus`;
    const participantsList = {
      attendId: +acceptedUser.attendId,
      status: "ACCEPTED",
    };
    console.log("Participant List", participantsList);

    const params = {
      type: "single",
      participant: participantsList,
    };
    const request = await axios.post(url, params);
    console.log("Request Data :", request.data.data);

    //WebSocket request
    if (request.data.data?.entry === "finished") {
      changUserStatus([+acceptedUser.attendId]);
    }

    if (request.data.data?.entry === "finished") {
      const filterList = participantRequestedList.filter(
        (user) => user.attendId !== +acceptedUser?.attendId
      );
      console.log("filterList", filterList);
      setParticipantRequestedListCount(participantRequestedListCount - 1);
      setParticipantRequestedList(filterList);
    }
  };

  const handleRejectedRequest = async (rejectedUser) => {
    // console.log(user);
    const url = `${CHAT_URL}api/meetingAcceptRejectStatus`;
    const participantsList = {
      attendId: +rejectedUser.attendId,
      status: "REJECTED",
    };
    console.log("Participant List", participantsList);

    const params = {
      type: "single",
      participant: participantsList,
    };
    const request = await axios.post(url, params);
    console.log("Request Data :", request.data.data);

    if (request.data.data?.entry === "finished") {
      setParticipantRequestedList(({ attendId }) => ({
        attendId: +attendId,
        status: "REJECTED",
      }));
      setParticipantRequestedListCount(
        participantRequestedListCount?.length - 1
      );
    }
  };

  const handleJitsiIFrameRef1 = (iframeRef) => {
    iframeRef.style.background = "#3d3d3d";
    iframeRef.style.height = "100%";
  };

  const timeoutAfter = (seconds) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("request timed-out"));
      }, seconds * 1000);
    });
  };

  const updateStatus = async () => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(meetingDetails),
    };
    return fetch(`${CHAT_URL}api/updateMeetingStatus/`, config);
  };

  const printEventOutput = (payload) => {
    updateLog((items) => [...items, JSON.stringify(payload)]);
  };

  const handleAudioStatusChange = (payload, feature) => {
    if (payload.muted) {
      // console.log("Entered off");
      updateLog((items) => [...items, `${feature} off`]);
    } else {
      // console.log("Entered on");
      updateLog((items) => [...items, `${feature} on`]);
    }
  };

  const handleCloseMeeting = async () => {
    console.log("attenderDetails", attenderDetails);
    const url = `${CHAT_URL}api/meetingEnd/${isModerator ? "admin" : "user"}`;
    let params = {};
    if (!isModerator) {
      params = {
        meetingId: attenderDetails.meetingId,
        leaveTime: new Date().toISOString(),
        attendId: attenderDetails?.attendDetails?.attendId,
      };
    } else {
      params = {
        meetingId: attenderDetails.meetingId,
        leaveTime: new Date().toISOString(),
      };
    }

    return axios.post(url, params);
  };

  const handleReadyToClose = async (e) => {
    try {
      // const [updateStatusRepsonse, handleCloseMeetingResponse] =
      //   await Promise.all([updateStatus(), handleCloseMeeting()]);
      // const data = await updateStatusRepsonse.json();
      // const handleCloseMeetingResponsedata =
      //   await handleCloseMeetingResponse.json();
      const [handleCloseMeetingResponse] = await Promise.all([
        handleCloseMeeting(),
      ]);
      // const data = await updateStatusRepsonse.json();
      const handleCloseMeetingResponsedata =
        await handleCloseMeetingResponse.json();
      history.push("/Left");
      console.log(
        "handleCloseMeetingResponsedata",
        handleCloseMeetingResponsedata
      );
      // console.log("RESPONSE RESPONSE :", handleCloseMeetingResponsedata);
      // const meetingStatus = data;

      // if (meetingStatus.status === "success") {
      //   history.push("/Left");
      // } else {
      // history.push("/Left");
      // }
      // console.log("Meeting Details", meetingDetails);
    } catch (err) {
      history.push("/Left");
    }
  };

  const handleChatUpdates = (payload) => {
    if (payload.isOpen || !payload.unreadCount) {
      return;
    }
    apiRef.current.executeCommand("toggleChat");
    updateLog((items) => [
      ...items,
      `you have ${payload.unreadCount} unread messages`,
    ]);
  };

  const handleKnockingParticipant = (payload) => {
    updateLog((items) => [...items, JSON.stringify(payload)]);
    updateKnockingParticipants((participants) => [
      ...participants,
      payload?.participant,
    ]);
  };

  const resolveKnockingParticipants = (condition) => {
    knockingParticipants.forEach((participant) => {
      apiRef.current.executeCommand(
        "answerKnockingParticipant",
        participant?.id,
        condition(participant)
      );
      updateKnockingParticipants((participants) =>
        participants.filter((item) => item.id === participant.id)
      );
    });
  };
  const updateReferenceId = async (attendId, referenceId) => {
    let data = {
      referenceId: referenceId,
      attendId: attendId,
    };
    console.log("attend", data);
    if (attendId) {
      try {
        const makeUpdate = await axios(
          `${API_URL}auth/update-meeting-reference-id`,
          {
            method: "POST",
            headers: {
              Accept: "*/*",
              "content-type": "application/json",
            },
            data: JSON.stringify(data),
          }
        );
        const result = await makeUpdate.data;
        console.log("reference-id-updated-status", result);
      } catch (e) {
        console.log("reference-id-updated-error", e);
      }
    }
  };
  const handleModerationParticipantApproved = (event) => {
    // Handle the "moderationParticipantApproved" event change
    console.log("apiRef Moderation Participant Approved:", event);
    // if (!jitsiId) {
    //   jitsiId = event?.id;
    //   updateReferenceId(attenderDetails.attendDetails?.attendId, event?.id);
    // }

    if (event?.role === "moderator") {
      console.log("apiRef Moderation Participant Approved Done");
      setIsModerator(true);
      handleShowParticpantList();
    }
  };

  const handleModerationStatusChanged = (event) => {
    // Handle the "moderationStatusChanged" event change
    console.log("apiRef Moderation Status Changed:", event);
  };
  const handleParticipantLeft = (list) => {
    // console.log("left moderator", isModerator);
    // console.log("left meeting-id", jitsiId);
    console.log("left meeting-left", list);
  };
  const handleApiReady = (apiObj) => {
    console.log("apiRef", apiObj);

    apiRef.current = apiObj;

    apiRef.current.on(
      "participantRoleChanged",
      handleModerationParticipantApproved
    );
    apiRef.current.on("moderatorStatusChanged", handleModerationStatusChanged);

    apiRef.current.on("knockingParticipant", handleKnockingParticipant);
    apiRef.current.on("audioMuteStatusChanged", (payload) =>
      handleAudioStatusChange(payload, "audio")
    );
    apiRef.current.on("videoMuteStatusChanged", (payload) =>
      handleAudioStatusChange(payload, "video")
    );
    apiRef.current.on("raiseHandUpdated", printEventOutput);
    apiRef.current.on("titleViewChanged", printEventOutput);
    apiRef.current.on("chatUpdated", handleChatUpdates);
    apiRef.current.on("knockingParticipant", handleKnockingParticipant);
    apiRef.current.on("participantLeft", handleParticipantLeft);
    console.log("apiRef", apiRef);
  };

  const handleModalOpen = () => {
    setOpenDrawer(true);
  };

  const handleModalClose = () => {
    setOpenDrawer(false);
  };

  const handleShowParticpantList = () => {
    setShowParticipantList(true);
  };

  React.useEffect(() => {
    console.log("Attender List :", attendeDetails);

    if (attendeDetails && attendeDetails !== "") {
      setRequestStatus(attendeDetails?.attendDetails?.status);
      setIsModerator(attendeDetails?.isModerator);
      setAttenderDetails(attendeDetails);
      setParticipantRequestedListCount(attendeDetails.participantList?.length);
      setParticipantRequestedList(attendeDetails.participantList);
    } else {
      setErrorMessage(ErrorMessage["NotConnecting"]);
    }
  }, [attendeDetails]);

  useEffect(() => {
    if (isModerator) {
      if (participantRequestedListCount !== 0) {
        setOpenDrawer(true);
        handleShowParticpantList();
      }
    }
  }, [participantRequestedListCount]);

  const list = (anchor) => (
    <>
      {participantRequestedListCount > 0 && (
        <Dialog
          open={openDrawer}
          onClose={handleModalClose}
          TransitionComponent={Transition}
          fullWidth
          maxWidth={`${participantRequestedListCount > 1 ? "md" : "sm"}`}
          classes={{
            paper: classes.dialog,
          }}
        >
          <DialogTitle>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "start",
                // gap: "1rem",
                flexDirection: "column",
              }}
            >
              {/* Multi user */}
              {participantRequestedListCount > 1 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                    // flex: "1",
                  }}
                >
                  {participantRequestedListCount > 1 && (
                    <AvatarGroup max={2} className={`${classes.avatar}`}>
                      {participantRequestedList.map((user) => (
                        <Avatar>
                          {user.userName?.split("")[0]?.toUpperCase() || "FU"}
                        </Avatar>
                      ))}
                    </AvatarGroup>
                  )}
                  <RxCrossCircled
                    style={{ marginBottom: "14px", cursor: "pointer" }}
                    onClick={() => handleModalClose()}
                  />
                </div>
              )}

              <div
                style={{
                  flex: `${participantRequestedListCount > 1 ? "8" : "12"}`,
                }}
              >
                <p className={`${utilsClasses.text}`}>
                  {participantRequestedListCount > 1
                    ? MessageContants["MultipleUser"]
                    : MessageContants["SingleUser"]}
                </p>
                {/* <span>{participantRequestedList[0]?.userName?.toUpperCase ? participantRequestedList[0].userName.toUpperCase  : "Fellow Users"}</span> */}
              </div>
              {/* Single user */}
              {/* {participantRequestedListCount === 1 && (
                <div
                  style={{
                    flex: "1",
                  }}
                >
                  <List>
                    {participantRequestedList.map((user) => (
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            {user.userName?.split("")[0]?.toUpperCase() || "FU"}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            user?.userName?.toUpperCase() || "FELLOW USER"
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>
              )} */}
            </div>
          </DialogTitle>
          <DialogContent
            dividers={`${participantRequestedListCount > 1 ? "paper" : ""}`}
          >
            {showParticipantList && (
              <div>
                {/* <List> */}
                {participantRequestedList.map((user) => (
                  <div className="user-list">
                    {/* <ListItem>
                        <ListItemAvatar> */}
                    <Avatar>
                      {user.userName?.split("")[0]?.toUpperCase() || "FU"}
                    </Avatar>
                    {/* </ListItemAvatar> */}
                    <div style={{ marginLeft: "1rem" }}>
                      <ListItemText
                        primary={user?.userName?.toUpperCase() || "FELLOW USER"}
                      />
                    </div>
                    {/* <ListItemSecondaryAction> */}
                    <div style={{ marginLeft: "auto" }}>
                      <Button
                        onClick={() =>
                          handleRejectedRequestList(user, "single")
                        }
                        color="secondary"
                        variant="outlined"
                      >
                        DENY
                      </Button>
                      <Button
                        onClick={() => handleAcceptedRequest(user)}
                        color="primary"
                        variant="outlined"
                        style={{ marginLeft: "1rem" }}
                      >
                        Admit
                      </Button>
                    </div>
                    {/* </ListItemSecondaryAction> */}
                    {/* </ListItem> */}
                  </div>
                ))}
                {/* </List> */}
              </div>
            )}
            <Warning participantList={participantRequestedList} />
          </DialogContent>
          {participantRequestedListCount > 1 && (
            <DialogActions>
              <Button
                onClick={() =>
                  handleRejectedRequestList(
                    participantRequestedList,
                    "multiple"
                  )
                }
                color="secondary"
                variant="outlined"
              >
                {participantRequestedListCount > 1 ? " Deny All" : "Deny Entry"}
              </Button>
              <Button
                onClick={() =>
                  participantRequestedListCount > 1
                    ? showParticipantList
                      ? handleAcceptedRequestList(
                          participantRequestedList,
                          "all"
                        )
                      : handleShowParticpantList()
                    : handleAcceptedRequestList(
                        participantRequestedList,
                        "single"
                      )
                }
                color="primary"
                variant="outlined"
              >
                {participantRequestedListCount > 1
                  ? showParticipantList
                    ? "Admit All"
                    : "View All"
                  : "Admit"}
              </Button>
            </DialogActions>
          )}
        </Dialog>
      )}
    </>
  );

  const meetingStatus = (status, checked) => {
    console.log("Audio-status", status);
    setUserPreferences(status);
    setOptionScreen(false);
    if (status !== "not-need" && !checked) {
      setPreviewPopUp(true);
      setTimeout(() => {
        setPreviewPopUp(false);
        console.log("previewPop-up");
      }, 2000);
    }
  };
  const previewStatus = () => {
    setPreviewPopUp(false);
  };
  return (
    <div
      style={{
        display: "flex",
        // height: "100vh",
        height:
          window.matchMedia("(max-width:739px)").matches &&
          requestStatus === RequestStatus["ACCEPTED"] &&
          !optionsPopupNeed
            ? "calc(100vh - 50px)"
            : "100vh",
      }}
    >
      {requestStatus === RequestStatus["ACCEPTED"] && optionsPopupNeed ? (
        <>
          <Options
            meetingStatus={meetingStatus}
            displayName={attenderDetails.attendDetails.userName}
            speaker={details?.isSpeaker == "yes" ? true : false}
          />
        </>
      ) : requestStatus === RequestStatus["ACCEPTED"] ? (
        <div
          style={{
            display: "flex",
            flex: "1",
          }}
        >
          <div
            className="background"
            style={{ flex: "1", position: "relative" }}
          >
            {previewModel && <PreviewPopup handleModalClose={previewStatus} />}{" "}
            <JitsiMeeting
              domain={"jitsibase.yuukke.org"}
              roomName={roomName}
              config={{
                subject: "Meeting",
                hideConferenceSubject: false,
              }}
              userInfo={{
                displayName:
                  attenderDetails.attendDetails.userName || "Fellow Users",
                // displayName: userName,
              }}
              onApiReady={(externalApi) => handleApiReady(externalApi)}
              onReadyToClose={handleReadyToClose}
              getIFrameRef={handleJitsiIFrameRef1}
              configOverwrite={{
                prejoinPageEnabled: isModerator ? false : true,
                hideParticipantsStats: isModerator ? false : true,
                disableDeepLinking: true,
                startWithAudioMuted:
                  userPreferences === "audio" || userPreferences === "both"
                    ? false
                    : true,
                startWithVideoMuted:
                  userPreferences === "video" || userPreferences === "both"
                    ? false
                    : true,
                // disableInitialGUM:
                //   userPreferences === "not-need" ? true : false,
                constraints: {
                  video: {
                    maxBitratesVideo: {
                      low: "auto",
                      standard: "auto",
                      high: "auto",
                    },
                  },
                },
              }}
              interfaceConfigOverwrite={{
                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
              }}
            />
          </div>
          {moderatorNeed &&
            isModerator &&
            participantRequestedListCount > 0 && (
              <div
                className="berger-menu"
                // onClick={toggleDrawer("right", true)}
                onClick={() => handleModalOpen()}
              >
                {participantRequestedListCount > 0 && (
                  <span
                    style={{
                      background: "white",
                      height: "10px",
                      width: "10px",
                      /* width: 24px; */
                      padding: "8px",
                      color: "#d92267",
                      borderRadius: "50%",
                      position: "absolute",
                      top: "-10px",
                      right: "-3px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {participantRequestedListCount}
                  </span>
                )}

                <RxHamburgerMenu
                  style={{
                    width: "26px",
                    height: "26px",
                    color: "white",
                  }}
                />
              </div>
            )}

          {moderatorNeed &&
            isModerator &&
            participantRequestedListCount > 0 && (
              // <div>
              //    <SwipeableDrawer
              //     anchor="right"
              //      open={openDrawer}
              //      onClose={DrawerClose("right", false)}
              //      onOpen={DrawerOpen("right", true)}
              //    >
              //      {list("right")}
              //    </SwipeableDrawer>
              // </div>
              <>{list("right")}</>
            )}
        </div>
      ) : (
        <></>
      )}

      {requestStatus === RequestStatus["REJECTED"] && (
        <>
          <Error
            message={ErrorMessage["ApprovaCancleled"]}
            showOnlyMessage
            status={RequestStatus["REJECTED"]}
          />
        </>
      )}

      {requestStatus === RequestStatus["PENDING"] && (
        <>
          <Error
            message={
              !moderatorNeed
                ? ErrorMessage["WaitingForModeratorJoin"]
                : ErrorMessage["WaitingForApproval"]
            }
            showOnlyMessage
            status={RequestStatus["PENDING"]}
          />
        </>
      )}
    </div>
  );
}

export default Meeting;
