import { Dialog, Slide } from "@material-ui/core";
import React from "react";
import { CiSliderHorizontal } from "react-icons/ci";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Mic from "../../images/mic_unmute.jpg";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const PermissionAlert = ({ status, closeModel }) => {
  return (
    <Dialog
      maxWidth="lg"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      style={{ background: "gray" }}
    >
      <div className="permission-alert">
        <img style={{ height: "15rem", width: "auto" }} src={Mic} />
        <div style={{ marginTop: "2rem" }}>
          <h4>Meet has been blocked from using your {status}</h4>
          <ul className="per-content">
            <li>
              Click the{" "}
              <CiSliderHorizontal
                style={{ width: "auto", height: "14px", color: "black" }}
              />{" "}
              page info icon in your browser's address bar.
            </li>
            <li>
              Turn on <b>{status}</b>
            </li>
            <li style={{ textAlign: "center", listStyle: "none" }}>(or)</li>
            <li>Close other apps that might be using your camera</li>
            <li>If using an external camera , disconnect and reconnect it.</li>
            <li>Close and reopen your browser.</li>
          </ul>
        </div>
        <IoMdCloseCircleOutline
          className="permission-alert-svg"
          onClick={() => closeModel(status)}
        />
      </div>
    </Dialog>
  );
};

export default PermissionAlert;
