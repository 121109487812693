import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";

function VideoDetecter({
  videoStatus,
  name,
  status,
  updatePermission,
  permissionStatus,
}) {
  const [hasCameraPermission, setHasCameraPermission] = useState(false);
  const webcamRef = React.createRef();

  useEffect(() => {
    async function requestCameraAccess() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setHasCameraPermission(true);
        stream.getTracks().forEach((track) => track.stop());
        updatePermission("videoPermission", "granted", status, false);
      } catch (error) {
        // if (error.name === "NotAllowedError") {

        // }
        console.log("Camera access denied.", error);
        setHasCameraPermission(false);
        updatePermission("videoPermission", "error", status, true);
      }
    }
    if (videoStatus) {
      requestCameraAccess();
    } else {
      setHasCameraPermission(false);
    }
  }, [videoStatus]);

  return (
    <div className="video-box">
      {hasCameraPermission ? (
        <Webcam
          ref={webcamRef}
          audio={false}
          height={"100%"}
          screenshotFormat="image/jpeg"
          width={280}
        />
      ) : (
        <p
          className="not-permit"
          style={{ color: permissionStatus === "granted" ? "green" : "red" }}
        >
          {permissionStatus === "granted"
            ? "Camera is working"
            : "Camera access is required."}
        </p>
      )}
      <h5 className="com-d-name">{name}</h5>
    </div>
  );
}
export default VideoDetecter;
