import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API_URL, CHAT_URL, YUUKKE_URL } from "./config";
import FullPageLoading from "./FullPageLoading";
import Meeting from "./components/Meeting/GroupMeeting";
import { demoParticipantList, ErrorMessage } from "./components/constants";
import Register from "./components/Register/Register";
import Error from "./components/Error/Error";
import PersonalMeeting from "./components/Meeting/PersonalMeeting";
import Keycloak from "keycloak-js";
import { useDeviceData } from "react-device-detect";

var moderateUser = false;
const App = () => {
  const location = useLocation();
  console.log("Location Details :", location.state);
  const [showLoader, setShowLoader] = React.useState(true);
  const [isMeetingLinkAvailable, setIsMeetingLinkAvailable] =
    React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [roomName, setRoomName] = React.useState("");
  const [meetingDetails, setMeetingDetails] = React.useState(null);
  const [showUserRegisterationModal, setShowUserRegisterationModal] =
    React.useState(false);
  const [registeredUser, setRegisteredUser] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [attenderDetails, setAttenderDetails] = React.useState(null);
  const [checkSelected, setCheckSelected] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [participantRequestedList, setParticipantRequestedList] =
    React.useState(demoParticipantList);
  const SectionData = sessionStorage.getItem("userDetails");
  const userDetails = SectionData ? JSON.parse(SectionData) : "";
  const [keyClockLogin, setKeyClockLogin] = useState(false);
  const [loginNeed, setLoginNeed] = useState(false);
  const [jsonGet, setJsonGet] = useState(false);
  const [userLoginReg, setUserLoginReg] = useState(false);
  const sectionUserData = sessionStorage.getItem("user");
  const [yuukkeUser, setYuukkeUser] = useState(false);
  const userData = sectionUserData ? JSON.parse(sectionUserData) : null;
  const [meetingEnd, setMeetingEnd] = useState(false);
  const [moderatorNeed, setModeratorNeed] = useState(false);
  const deviceInfo = useDeviceData();
  console.log("attenderDetails", attenderDetails);
  console.log("attenderDetails userData", userData);
  const handleClose = () => {
    setShowModal(false);
  };

  const meetingTimeValidate = (isAvailableResponse = {}) => {
    return (
      new Date(isAvailableResponse?.data?.message?.meeting?.meetingTime) <
        new Date() &&
      isAvailableResponse?.data?.message?.meeting?.isMeetingfinished === false
    );
  };

  const meetingFinishedValidate = (isAvailableResponse) => {
    return (
      isAvailableResponse?.data?.message?.meeting?.isMeetingfinished === true
    );
  };

  const getJwtToken = (userDetails) => {
    const tokenUrl = `${CHAT_URL}api/users`;
    // const roomNameUrl =
    try {
      axios
        .post(tokenUrl, {
          ...userDetails,
        })
        .then((response) => {
          var result = response.data;

          if (result.message == "success") {
            console.log("userDetails", userDetails);
            const url = userDetails.meetingName + "?jwt=" + result.data;

            console.log("JWT", url);
            setRoomName(url);
          } else {
            console.log("JWT NOT GET");
            setRoomName("");
            setErrorMessage(ErrorMessage["NoMeetingAvailiable"]);
          }
        })
        .catch((error) => {
          setRoomName("");
          setErrorMessage(ErrorMessage["NoMeetingAvailiable"]);
        });
    } catch (err) {
      setRoomName("");
      setErrorMessage(ErrorMessage["NoMeetingAvailiable"]);
      console.log("Room not found");
    }
  };
  const checkisMeetingAvailable = async () => {
    const url = `${CHAT_URL}api/isMeetingAvailiable${userDetails?.meetingLink}/${userData?.userId}`;
    // const url = `${CHAT_URL}api/isMeetingAvailiable${userDetails?.meetingLink}/${userDetails?.userId}/${userDetails?.friend}`;

    console.log(url);

    try {
      const isAvailableRequest = await axios.get(url);
      const isAvailableResponse = isAvailableRequest.data;
      console.log("Meeting Status", isAvailableResponse);

      if (isAvailableResponse.status === "success") {
        if (meetingTimeValidate(isAvailableResponse)) {
          const meetingAttendedUserRequestParamforFriendAvailiable = {
            userId: userData?.userId,
            meetingName: userDetails?.meetingLink,
          };

          setMeetingDetails({
            ...isAvailableResponse.data,
            loginedUserId: userData?.userId,
          });

          setIsMeetingLinkAvailable(true);

          //Get jwt token
          var link = userDetails?.meetingLink.replace(/\//g, "");
          console.log("Jwt attenderDetails", attenderDetails);
          if (!attenderDetails) {
            //Moderator user
            console.log("Moderator", moderateUser);

            var moderatorLink = {};
            if (userDetails?.isGlob === "true") {
              moderatorLink = {
                userId: userData?.userId,
                meetingName: link,
                moderateUser: moderateUser,
                yuukkeUser: keyClockLogin ? true : false,
                // moderateUser: false,
                userName: userData?.displayName,
                globe: true,
              };
            } else {
              moderatorLink = {
                userId: userData?.userId,
                meetingName: link,
                // moderateUser: userDetails?.yLogin ? false : true,
                userName: userData?.displayName,
                globe: false,
                moderateUser: moderateUser,
                yuukkeUser: keyClockLogin ? true : false,
              };
            }

            getJwtToken(moderatorLink);
          } else {
            //new user
            const meeting = {
              userId: attenderDetails?.attendDetails?.attendId,
              meetingName: link,
              moderateUser: false,
              userName: attenderDetails?.attendDetails?.userName,
              globe: false,
              yuukkeUser: false,
            };
            getJwtToken(meeting);

            userDetails.authenticated = true;
            sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
            console.log("isAuthorizedUser after", userDetails);
          }
          // setRoomName(userDetails?.meetingLink);
        } else if (meetingFinishedValidate(isAvailableResponse)) {
          setErrorMessage(ErrorMessage["meetingFinished"]);
        } else {
          setErrorMessage(ErrorMessage["meetingNotStarted"]);
        }
      } else {
        setErrorMessage(ErrorMessage["NoMeetingAvailiable"]);
      }
    } catch (err) {
      console.log("Error : -> ", err);
      setShowLoader(false);
      setErrorMessage(ErrorMessage["NotConnecting"]);
    }
  };
  const needKeyClockLogin = () => {
    setKeyClockLogin(false);
    userDetails.loginStatus = true;
    userDetails.authenticated = true;
    userDetails.yLogin = true;
    sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
    setShowUserRegisterationModal(false);
    setRoomName("");
  };
  const joinApiRequestForFriendAvailiable = async () => {
    const meetingLink = userDetails?.meetingLink.replace(/\//g, "");
    const meetingAttendedUserRequestParamforFriendAvailiable = {
      // meetingName: userDetails?.meetingLink.substring(2),
      meetingName: meetingLink,
      // userId: userDetails?.friend,
      userId: userData?.userId,
      deviceInfo: JSON.stringify(deviceInfo),
    };

    const meetingAttendedUserFriendAvailiableRequest = await axios.post(
      `${API_URL}auth/meeting-user-join`,
      meetingAttendedUserRequestParamforFriendAvailiable
    );
    console.log(
      "Friend Details",
      meetingAttendedUserFriendAvailiableRequest.data.data
    );
    if (
      meetingAttendedUserFriendAvailiableRequest.data?.data?.attendDetails
        ?.attendId
    ) {
      //Set Moderate user or not
      moderateUser =
        meetingAttendedUserFriendAvailiableRequest.data.data?.isModerator;
      setAttenderDetails(meetingAttendedUserFriendAvailiableRequest.data.data);

      checkisMeetingAvailable();
    } else {
      setErrorMessage("Meeting Link Is Wrong !");
    }
  };

  useEffect(() => {
    // console.log(data.replace(/\//g, ""));
    console.log("userDetails", userDetails);

    if (
      userDetails?.meetingLink &&
      (userDetails?.isGroup === "true" || userDetails?.isGlob === "true")
    ) {
      let apiData = userDetails?.meetingLink.replace(/\//g, "");
      let data = {
        meetingName: apiData,
      };
      axios
        .post(`${API_URL}auth/meeting-expiration-check`, data)
        .then((response) => {
          // if (result?.data?.data === "NOT_VALID") {
          //   setErrorMessage(ErrorMessage["meetingFinished"]);
          //   setMeetingEnd(true);
          // }
          console.log("meeting-expiration-check", response?.data?.data);
          const result = response?.data?.data;
          if (result?.status === "NOT_VALID") {
            setErrorMessage(ErrorMessage["meetingFinished"]);
            setMeetingEnd(true);
          } else if (result?.status === "INVALID_CREDENTIAL") {
            setErrorMessage(ErrorMessage["NoMeetingAvailiable"]);
          } else {
            setModeratorNeed(result?.moderationNeed);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  }, []);
  useEffect(() => {
    console.log("sectionUser", userData);

    //Access KeyClock login
    if (
      userDetails?.loginStatus == true &&
      !keyClockLogin &&
      !sessionStorage.getItem("Token")
    ) {
      console.log("Trigger KeyClock login");
      setLoginNeed(true);
    } else {
      console.log("Start To validation", userDetails);
      //Moderator user
      if (userDetails && userDetails?.loginStatus) {
        //   if (userDetails?.friend !== "undefined"
        //  && userDetails) {
        console.log(userDetails);
        console.log("Entering Allowed State");
        if (userDetails?.isGroup === "true" || userDetails?.isGlob === "true") {
          joinApiRequestForFriendAvailiable();
        } else {
          checkisMeetingAvailable();
        }
      }
      // User Need to register
      else if (
        userDetails &&
        // userDetails.friend === "undefined" &&
        (userDetails?.isGroup === "true" || userDetails?.isGlob === "true") &&
        !userDetails?.loginStatus
      ) {
        console.log(
          "Entering Friend undefined state",
          sessionStorage.getItem("Token")
        );

        if (!registeredUser && !sessionStorage.getItem("Token")) {
          console.log("Enter Register");
          setShowUserRegisterationModal(true);
          setShowModal(true);
        } else if (!jsonGet) {
          console.log("Entering nested");
          checkisMeetingAvailable();
          setJsonGet(true);
        }
      }
      //Mentor meeting user
      else if (
        userDetails &&
        // userDetails?.friend === "undefined" &&
        userDetails?.isGroup === "false"
      ) {
        console.log("Entering Mentor Meeting");
        checkisMeetingAvailable();
      } else {
        setErrorMessage(ErrorMessage["NotAuthorized"]);
      }
    }
  }, [registeredUser, showUserRegisterationModal, keyClockLogin, userLoginReg]);
  useEffect(() => {
    if (loginNeed && !sessionStorage.getItem("Token")) {
      const key = Keycloak("/keycloak.json");
      console.log("Key: " + key);
      key
        .init({
          onLoad: "login-required",
        })

        .then(async (authenticated) => {
          console.log("Authenticated: ", authenticated);
          if (!!key.token) {
            let userInfo = await key.loadUserInfo();
            sessionStorage.setItem("Token", key.token);
            const options = {
              url: `${CHAT_URL}api/users/get/details`,
              headers: {
                Authorization: `Bearer ${key.token}`,
              },
            };
            await axios
              .get(options.url, { headers: options.headers })
              .then((response) => {
                let result = response.data.data;
                console.log("Get User Details", result);
                sessionStorage.setItem("user", JSON.stringify(result));
              });

            console.log("userInfo key", key);
            setKeyClockLogin(true);
          }
        });
    }
  }, [loginNeed]);

  const handleCancel = () => {
    setErrorMessage(ErrorMessage["Rejected"]);
    // history.push("/Left")
  };

  const checkisAuthorizedtoJoinCall = () => {
    const isAuthorizedUser =
      isMeetingLinkAvailable &&
      errorMessage === "" &&
      !meetingDetails.message.meeting.isMeetingfinished &&
      roomName !== "";
    return isAuthorizedUser;
  };

  if (!meetingEnd && showUserRegisterationModal) {
    return (
      <Register
        setAttenderDetails={setAttenderDetails}
        userDetails={userDetails}
        setShowUserRegisterationModal={setShowUserRegisterationModal}
        setRegisteredUser={setRegisteredUser}
        handleClose={handleClose}
        handleCancel={handleCancel}
        showModal={showModal}
        needKeyClockLogin={needKeyClockLogin}
        deviceInfo={deviceInfo}
      />
    );
  }

  return (
    <div>
      {meetingEnd ? (
        <Error message={ErrorMessage["meetingFinished"]} />
      ) : checkisAuthorizedtoJoinCall() ? (
        userDetails?.authenticated ? (
          userDetails?.isGroup === "true" || userDetails?.isGlob === "true" ? (
            attenderDetails ? (
              <Meeting
                attenderDetails={attenderDetails}
                roomName={roomName}
                meetingDetails={meetingDetails}
                setErrorMessage={setErrorMessage}
                moderatorNeed={moderatorNeed}
                details={userDetails}
              />
            ) : (
              <Error message={ErrorMessage["NotAuthorized"]} />
            )
          ) : (
            <PersonalMeeting
              roomName={roomName}
              meetingDetails={meetingDetails}
              setErrorMessage={setErrorMessage}
              userName={userData?.displayName || "Fellow Users"}
            />
          )
        ) : (
          <Error message={ErrorMessage["NotAuthorized"]} />
        )
      ) : showLoader && errorMessage === "" && meetingDetails === null ? (
        <FullPageLoading />
      ) : (
        <Error
          message={
            meetingDetails?.message?.meeting?.isMeetingfinished
              ? ErrorMessage["meetingFinished"]
              : errorMessage
          }
        />
      )}
    </div>
  );
};

export default App;
