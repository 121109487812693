import React, { useEffect, useState } from "react";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { CHAT_URL } from "../../config";
import { useHistory } from "react-router-dom";
import {
  ErrorMessage,
  MessageContants,
  RequestStatus,
  warnings,
} from "../constants";
import Error from "../Error/Error";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Slide,
  ListItemAvatar,
  ListItemSecondaryAction,
} from "@material-ui/core";
import Sidebar from "../Sidebar/Sidebar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from "@material-ui/core/ListItemText";
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { RxHamburgerMenu } from "react-icons/rx";
import { GrRefresh, GrStatusWarning } from "react-icons/gr";
import Checkbox from "@material-ui/core/Checkbox";
import { demoParticipantList } from "../constants";
// import { Slide } from "@material-ui/core";
import axios from "axios";
import { useInterval } from "../../hooks/useInterval";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Options from "./Options";
import PreviewPopup from "./PreviewPopup";

const useStyles = makeStyles({
  list: {
    width: 500,
  },
  fullList: {
    width: "auto",
  },

  avatar: {
    flex: 1,
    height: "64px",
    width: "64px",

    "&__grouped": {
      height: "64px",
      width: "64px",
    },

    "@media(minWidth: 760px)": {
      height: "64px",
      width: "64px",
    },
  },

  dialog: {
    position: "absolute !important",
    top: 4,
  },
});

const useStylesUtils = makeStyles({
  text: {
    margin: 0,
    fontWeight: "bold",
    fontSize: "x-large",
  },

  subText: {
    margin: 0,
    fontSize: "medium",
  },
});

const useStylesWarning = makeStyles({
  warning: {
    background: "#97D2CE",
    borderRadius: "10px",
    display: "flex",
    alignItems: "stretch",
    // justifyContent:"center",
    gap: "0.5rem",
    padding: "1rem",

    "&__content": {
      flex: "1",
      fontSize: "bold",
      display: "flex",
      flexDirection: "column",
      gap: "0.8rem",
      // padding:"1rem",
      paddingInline: "1rem",
    },
  },
});

const Warning = ({ participantList = [] }) => {
  const classes = useStylesWarning();
  const utilsClasses = useStylesUtils();

  return (
    <div className={classes.warning}>
      <GrStatusWarning
        style={{
          width: "32px",
          height: "32px",
          color: "white",
        }}
      />
      <div className={`${classes.warning}__content`}>
        {participantList?.length > 0 ? (
          <p className={`${utilsClasses.text}`}>
            {participantList?.length} persons might not be whom they claim to be
          </p>
        ) : (
          <p className={`${utilsClasses?.text}`}>{warnings["title"]}</p>
        )}
      </div>
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function PersonalMeeting({
  roomName,
  userName = "Fellow Users",
  meetingDetails,
  setErrorMessage,
}) {
  const apiRef = React.useRef();
  const history = useHistory();
  const classes = useStyles();
  const utilsClasses = useStylesUtils();
  const [logItems, updateLog] = React.useState([]);
  const [knockingParticipants, updateKnockingParticipants] = React.useState([]);
  const [isModerator, setIsModerator] = React.useState(false);
  const [options, setOptionScreen] = useState(false);
  const [userPreferences, setUserPreferences] = useState("");
  const [previewModel, setPreviewPopUp] = useState(false);
  const handleJitsiIFrameRef1 = (iframeRef) => {
    iframeRef.style.background = "#3d3d3d";
    iframeRef.style.height = "100%";
  };

  const timeoutAfter = (seconds) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("request timed-out"));
      }, seconds * 1000);
    });
  };

  const updateStatus = async () => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(meetingDetails),
    };
    return fetch(`${CHAT_URL}api/updateMeetingStatus/`, config);
  };

  const printEventOutput = (payload) => {
    updateLog((items) => [...items, JSON.stringify(payload)]);
  };

  const handleAudioStatusChange = (payload, feature) => {
    if (payload.muted) {
      // console.log("Entered off");
      updateLog((items) => [...items, `${feature} off`]);
    } else {
      // console.log("Entered on");
      updateLog((items) => [...items, `${feature} on`]);
    }
  };

  const handleReadyToClose = async (e) => {
    try {
      const updateStatusRepsonse = await Promise.race([
        updateStatus(),
        timeoutAfter(20),
      ]);
      const data = await updateStatusRepsonse.json();
      const meetingStatus = data;
      if (meetingStatus.status === "success") {
        history.push("/Left");
      } else {
        history.push("/Left");
      }
    } catch (err) {
      history.push("/Left");
    }
  };

  const handleChatUpdates = (payload) => {
    if (payload.isOpen || !payload.unreadCount) {
      return;
    }
    apiRef.current.executeCommand("toggleChat");
    updateLog((items) => [
      ...items,
      `you have ${payload.unreadCount} unread messages`,
    ]);
  };

  const handleKnockingParticipant = (payload) => {
    updateLog((items) => [...items, JSON.stringify(payload)]);
    updateKnockingParticipants((participants) => [
      ...participants,
      payload?.participant,
    ]);
  };

  const resolveKnockingParticipants = (condition) => {
    knockingParticipants.forEach((participant) => {
      apiRef.current.executeCommand(
        "answerKnockingParticipant",
        participant?.id,
        condition(participant)
      );
      updateKnockingParticipants((participants) =>
        participants.filter((item) => item.id === participant.id)
      );
    });
  };

  const handleApiReady = (apiObj) => {
    apiRef.current = apiObj;
    apiRef.current.on("knockingParticipant", handleKnockingParticipant);
    apiRef.current.on("audioMuteStatusChanged", (payload) =>
      handleAudioStatusChange(payload, "audio")
    );
    apiRef.current.on("videoMuteStatusChanged", (payload) =>
      handleAudioStatusChange(payload, "video")
    );
    apiRef.current.on("raiseHandUpdated", printEventOutput);
    apiRef.current.on("titleViewChanged", printEventOutput);
    apiRef.current.on("chatUpdated", handleChatUpdates);
    apiRef.current.on("knockingParticipant", handleKnockingParticipant);
  };

  //   React.useEffect(() => {
  //    if(user) {
  //       setErrorMessage(ErrorMessage["NotConnecting"]);
  //     }
  //   }, [attendeDetails]);

  // useEffect(() => {
  //   console.log("navigator - 1", navigator.mediaDevices);
  //   navigator.mediaDevices
  //     .getUserMedia({ video: false })
  //     .then((e) => {
  //       // Audio permission revoked
  //       console.log("navigator", e);
  //     })
  //     .catch((error) => {
  //       console.error("navigator Error revoking audio permission:", error);
  //     });
  //   navigator.mediaDevices
  //     .getUserMedia({ audio: true })
  //     .then((e) => {
  //       // Audio permission revoked
  //       console.log("navigator", e);
  //     })
  //     .catch((error) => {
  //       console.error("navigator Error revoking audio permission:", error);
  //     });
  //   alert();
  // }, []);
  const meetingStatus = (status) => {
    console.log("Audio-status", status);
    setUserPreferences(status);
    setOptionScreen(true);
    if (status !== "not-need") {
      setPreviewPopUp(true);
      setTimeout(() => {
        setPreviewPopUp(false);
        console.log("previewPop-up");
      }, 2000);
    }
  };
  const previewStatus = () => {
    setPreviewPopUp(false);
  };
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        // width:"100vw"
      }}
    >
      <div
        style={{
          display: "flex",
          flex: "1",
        }}
      >
        <div style={{ flex: "1" }}>
          {!options ? (
            <Options meetingStatus={meetingStatus} />
          ) : (
            <>
              {previewModel && (
                <PreviewPopup handleModalClose={previewStatus} />
              )}{" "}
              <JitsiMeeting
                domain={"jitsibase.yuukke.org"}
                roomName={roomName}
                config={{
                  subject: "Meeting",
                  hideConferenceSubject: false,
                }}
                userInfo={{
                  displayName: userName,
                  // displayName: userName,
                }}
                onApiReady={(externalApi) => handleApiReady(externalApi)}
                onReadyToClose={handleReadyToClose}
                getIFrameRef={handleJitsiIFrameRef1}
                configOverwrite={{
                  disableDeepLinking: true,
                  startWithAudioMuted:
                    userPreferences === "audio" || userPreferences === "both"
                      ? false
                      : true,
                  startWithVideoMuted:
                    userPreferences === "video" || userPreferences === "both"
                      ? false
                      : true,
                  disableInitialGUM:
                    userPreferences === "not-need" ? true : false,
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PersonalMeeting;
