import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Slide } from "@material-ui/core";
import { AiOutlineCamera, AiOutlineVideoCamera } from "react-icons/ai";
import { MdOutlineKeyboardVoice } from "react-icons/md";
import { BiUserCircle } from "react-icons/bi";
import allow from "../../images/direction.png";
import { RxCrossCircled } from "react-icons/rx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewPopup({ handleModalClose }) {
  return (
    <div>
      <Dialog
        onClose={handleModalClose}
        maxWidth="md"
        open={true}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="close-icon">
          <RxCrossCircled
            style={{ marginBottom: "14px", cursor: "pointer" }}
            // onClick={() => handleModalClose()}
          />
        </div>
        <div className="image-container">
          <img src={allow} alt="img" />
        </div>
        <div className="options">
          <h3
            style={{
              fontSize: "20px",
              width: "auto",
              display: "flex",
              textAlign: "center",
            }}
          >
            Click Allow
          </h3>
          <p style={{ marginTop: "12px" }}>
            {" "}
            You can turn off your microphone and camera anytime you want
          </p>
        </div>
      </Dialog>
    </div>
  );
}
