import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Slide,
  TextField,
  Box,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import axios from "axios";
import { CHAT_URL } from "../../config";
import { RotatingLines } from "react-loader-spinner";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    padding: theme.spacing(0),
    background: "white",
    // margin: theme.spacing(0)
  },
  leftBarContainer: {
    height: "100vh",
  },
  leftBarMobileContainer: {
    height: "65vh",
  },
  leftBar: {
    height: "100%",
    // background: "blue",
    // padding: theme.spacing(),
    textAlign: "center",
    color: theme.palette.text.secondary,

    position: "relative",
  },

  rightBarContainer: {
    height: "100vh",
  },
  rightBarMobileContainer: {
    height: "35vh",
  },
  rightBar: {
    height: "100%",
    background: "black",
    // padding: theme.spacing(),
    textAlign: "center",
    color: theme.palette.text.secondary,

    position: "relative",
  },
  RightBoxContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    background: "white",
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    padding: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  leftBoxContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",

    width: "75%",
  },
  MobileBoxContainer: {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%,-50%)",
    // background: "white",
    // width: "100px",
    // height: "100px",
    // borderRadius: "50%",
    // padding: "3rem",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center"
  },
}));

function Register({
  userDetails,
  handleClose,
  setRegisteredUser,
  setShowUserRegisterationModal,
  showModal,
  setAttenderDetails,
  needKeyClockLogin,
  deviceInfo,
}) {
  const classes = useStyles();

  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = React.useState("");
  const [registeredUsername, setRegisteredUsername] = React.useState("");
  const [phoneNo, setPhneNo] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [nameError, setNameError] = useState("");
  const cancelRef = React.useRef();
  const submitRef = React.useRef();

  useEffect(() => {
    if (localStorage.getItem("USER")) {
      let data = JSON.parse(localStorage.getItem("USER"));
      console.log("user-data", data);
      setEmail(data?.email);
      setRegisteredUsername(data?.name);
      setPhneNo(data?.mobileNumber);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("keydown", handleEnter);
    return () => {
      window.removeEventListener("keydown", handleEnter);
    };
  }, [registeredUsername, phoneNo, email]);
  const handleEnter = (e) => {
    if (e?.code === "Enter") {
      handleSubmit();
    }
  };
  const handleSubmit = () => {
    if (registeredUsername === "") {
      setNameError("Enter your name");
    } else if (validateEmail(email)) {
      setEmailError("Invalid email address");
      setNameError("");
    } else if (phoneNo === "" || phoneNo?.length < 10) {
      setPhoneError("Invalid phoneNumber");
      setNameError("");
      setEmailError("");
    } else {
      let data = {
        name: registeredUsername,
        email: email,
        mobileNumber: phoneNo,
      };
      localStorage.setItem("USER", JSON.stringify(data));
      setNameError("");
      setEmailError("");
      setPhoneError("");
      setShowLoader(true);
      handleRegister(registeredUsername, email, phoneNo);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return "Invalid email address";
    }
    return "";
  };
  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setEmailError(validateEmail(value));
  };

  const handleRegister = async (registeredUsername, email, phoneNo) => {
    console.log(registeredUsername);
    console.log(email);
    console.log(phoneNo);
    const meetingLink = userDetails?.meetingLink.replace(/\//g, "");
    const meetingAttendedUserRequestParamforFriendNotAvailiable = {
      email: email,
      phone: phoneNo,
      userName: registeredUsername,
      meetingName: meetingLink,
      // meetingName: userDetails.meetingLink.substring(2),
      isUserExists: false,
      deviceInfo: JSON.stringify(deviceInfo),
    };

    console.log(
      "FRIEND USER DETAILS :",
      meetingAttendedUserRequestParamforFriendNotAvailiable
    );

    const meetingAttendedUserRequest = await axios.post(
      `${CHAT_URL}api/registerUser`,
      meetingAttendedUserRequestParamforFriendNotAvailiable
    );
    console.log("Friend User Details", meetingAttendedUserRequest.data);

    if (meetingAttendedUserRequest?.data?.data?.hasOwnProperty("isModerator")) {
      setAttenderDetails(meetingAttendedUserRequest?.data?.data);
      setRegisteredUser(true);
      setShowLoader(false);
      setShowUserRegisterationModal(false);
    }
  };

  return (
    // <Dialog
    //   open={showModal}
    //   onClose={handleClose}
    //   aria-labelledby="form-dialog-title"
    //   disableBackdropClick
    //   fullWidth="true"
    //   maxWidth="sm"
    // >
    //   <DialogTitle id="form-dialog-title"> Register for Join Meeting </DialogTitle>
    //   <DialogContent
    //     style={{
    //       display: "flex",
    //       flexDirection: "column",
    //       gap: "1rem",
    //     }}
    //   >
    //     <TextField
    //       autoFocus
    //       // margin="dense"
    //       id="name"
    //       label="Name"
    //       type="text"
    //       fullWidth
    //       variant="outlined"
    //       value={registeredUsername}
    //       onChange={(e) => setRegisteredUsername(e.target.value)}
    //     />
    //     <TextField
    //       autoFocus
    //       // margin="dense"
    //       id="email"
    //       label="Email Address"
    //       type="email"
    //       fullWidth
    //       variant="outlined"
    //       value={email}
    //       onChange={(e) => setEmail(e.target.value)}
    //     />
    //     <TextField
    //       autoFocus
    //       // margin="dense"
    //       id="mobileNo"
    //       label="Mobile Number"
    //       type="text"
    //       fullWidth
    //       variant="outlined"
    //       value={phoneNo}
    //       onChange={(e) => setPhneNo(e.target.value)}
    //     />
    //   </DialogContent>
    //   <DialogActions>
    //     <Button
    //       onClick={handleSubmit}
    //       style={{
    //         background: "#D92267",
    //         color: "white",
    //         height:"40px",
    //         padding:"16px 32px"
    //       }}
    //       variant="outlined"
    //     //   ref={submitRef}
    //     disabled={showLoader}
    //     >
    //       {
    //         showLoader ? (
    //             <RotatingLines
    //                 strokeColor="grey"
    //                 strokeWidth="5"
    //                 animationDuration="0.75"
    //                 width="32"
    //                 visible={true}
    //             />
    //         ) : (
    //             <>Register</>
    //         )
    //       }
    //     </Button>

    //     {/* <button
    //       onClick={handleCancel}
    //     //   color="#D92267"
    //     //   variant="outlined"
    //       style={{
    //         background: "#D92267",
    //         color: "white",
    //       }}
    //     >
    //       Cancel
    //     </button> */}
    //   </DialogActions>
    // </Dialog>
    <div className={classes.root}>
      <Hidden only={["xs"]}>
        <Grid container>
          <Grid item sm={6} md={6} className={classes.leftBarContainer}>
            <div className={classes.leftBar}>
              <Box className={classes.leftBoxContainer}>
                <p>Yuukke User</p>
                <TextField
                  required
                  autoFocus
                  // margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={registeredUsername}
                  onChange={(e) => setRegisteredUsername(e.target.value)}
                  error={!!nameError}
                  helperText={nameError}
                />
                <TextField
                  required
                  autoFocus
                  // margin="dense"
                  id="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!emailError}
                  helperText={emailError}
                />
                <TextField
                  required
                  autoFocus
                  // margin="dense"
                  id="mobileNo"
                  label="Mobile Number"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={phoneNo}
                  onChange={(e) => setPhneNo(e.target.value)}
                  error={!!phoneError}
                  helperText={phoneError}
                />
                <Button
                  onClick={handleSubmit}
                  style={{
                    background: "#D92267",
                    color: "white",
                    height: "40px",
                    padding: "16px 32px",
                  }}
                  variant="outlined"
                  //   ref={submitRef}
                  // disabled={showLoader}
                >
                  {showLoader ? (
                    <RotatingLines
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="32"
                      visible={true}
                    />
                  ) : (
                    <>Join now</>
                  )}
                  {/* Register */}
                </Button>
                <Button
                  onClick={() => needKeyClockLogin(true)}
                  variant="outlined"
                  color="primary"
                  href="#login"
                >
                  Login via Yuukke
                </Button>
              </Box>
            </div>
          </Grid>
          <Grid item sm={6} md={6} className={classes.rightBarContainer}>
            <div className={classes.rightBar}>
              <Box className={classes.RightBoxContainer}>
                <h3>Your name</h3>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only={["lg", "md", "sm", "xl"]}>
        <Grid container direction="column-reverse">
          <Grid item sm={6} md={6} className={classes.leftBarMobileContainer}>
            <div className={classes.leftBar}>
              <Box className={classes.leftBoxContainer}>
                <p>Yuukke User</p>
                <TextField
                  autoFocus
                  // margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={registeredUsername}
                  onChange={(e) => setRegisteredUsername(e.target.value)}
                  error={!!nameError}
                  helperText={nameError}
                />
                <TextField
                  autoFocus
                  id="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!emailError}
                  helperText={emailError}
                />
                <TextField
                  autoFocus
                  // margin="dense"
                  id="mobileNo"
                  label="Mobile Number"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={phoneNo}
                  onChange={(e) => setPhneNo(e.target.value)}
                  error={!!phoneError}
                  helperText={phoneError}
                />
                <Button
                  onClick={handleSubmit}
                  style={{
                    background: "#D92267",
                    color: "white",
                    height: "40px",
                    padding: "16px 32px",
                  }}
                  variant="outlined"
                  //   ref={submitRef}
                  // disabled={showLoader}
                >
                  {showLoader ? (
                    <RotatingLines
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="32"
                      visible={true}
                    />
                  ) : (
                    <>Join now</>
                  )}
                  {/* Register */}
                </Button>
                <Button
                  onClick={() => needKeyClockLogin(true)}
                  variant="outlined"
                  color="primary"
                  href="#login"
                >
                  Login via Yuukke
                </Button>
              </Box>
            </div>
          </Grid>
          <Grid item sm={6} md={6} className={classes.rightBarMobileContainer}>
            <div className={classes.rightBar}>
              <Box className={classes.RightBoxContainer}>
                <h3>Your name</h3>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Hidden>
    </div>
  );
}

export default Register;
