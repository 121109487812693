import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Slide, TextField } from "@material-ui/core";
import { AiOutlineCamera, AiOutlineVideoCamera } from "react-icons/ai";
import { MdOutlineKeyboardVoice } from "react-icons/md";
import { BiUserCircle } from "react-icons/bi";
import allow from "../../images/mic-allow.png";
import VideoDetector from "../Detector/VideoDetector";
import AudioDetector from "../Detector/AudioDetector";
import { IoMicSharp, IoMicOffSharp } from "react-icons/io5";
import { BsCameraVideoOffFill, BsCameraVideoFill } from "react-icons/bs";
import { FiVideo, FiVideoOff } from "react-icons/fi";
import PermissionAlert from "./PermissonAlert";
import { SiGooglemeet } from "react-icons/si";
import Meet from "../../images/meeting_logo.png";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ResponsiveDialog({
  meetingStatus,
  displayName,
  speaker,
}) {
  const [state, setState] = useState({
    audioStatus: false,
    videoStatus: false,
    displayName: displayName,
    audioPermission: "",
    videoPermission: "",
    permissionAudioWarn: false,
    permissionVideoWarn: false,
  });
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const closeModel = (status) => {
    if (status === "video") {
      setState({
        ...state,
        permissionVideoWarn: false,
        videoStatus: false,
      });
    } else {
      setState({
        ...state,
        permissionAudioWarn: false,
        audioStatus: false,
      });
    }
  };
  const updatePermission = (key, value, status, warn) => {
    if (status === "video") {
      setState({
        ...state,
        [key]: value,
        permissionVideoWarn: warn,
      });
    } else {
      setState({
        ...state,
        [key]: value,
        permissionAudioWarn: warn,
      });
    }
  };
  const joinMeeting = (e) => {
    console.log("e", e);
    if (!state.audioPermission || !state.videoPermission) {
      alert(
        `Please check your ${
          !state.audioPermission ? "microphone" : "video"
        } status`
      );
    } else if (
      state.audioPermission === "error" ||
      state.videoPermission === "error"
    ) {
      alert(
        `Meet has been blocked from using your ${
          state.audioPermission === "error" ? "microphone" : "video"
        } status , please give permission`
      );
      if (state.audioPermission === "error") {
        updateState("permissionAudioWarn", true);
      } else {
        updateState("permissionVideoWarn", true);
      }
    } else {
      if (state.audioStatus && state.videoStatus) {
        meetingStatus("both", true);
      } else if (state.audioStatus) {
        meetingStatus("audio", true);
      } else if (state.videoStatus) {
        meetingStatus("video", true);
      } else {
        meetingStatus("not-need", true);
      }
      // meetingStatus("video");
    }
  };
  // console.log("state", state);
  return (
    <div>
      <Dialog
        fullScreen={
          window.matchMedia("(min-width:768px)").matches ? false : true
        }
        maxWidth="lg"
        open={true}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        style={{ background: "black" }}
      >
        {state.audioPermission === "error" && state.permissionAudioWarn && (
          <PermissionAlert status={"microphone"} closeModel={closeModel} />
        )}
        {state.videoPermission === "error" && state.permissionVideoWarn && (
          <PermissionAlert status={"video"} closeModel={closeModel} />
        )}
        {speaker ? (
          <div className="option-container" style={{ boxShadow: "none" }}>
            <img className="meeting-img" src={Meet} alt="img" />
            <h4>Let's check your camera and mic</h4>
            <div className="detector">
              <VideoDetector
                videoStatus={state.videoStatus}
                name={displayName}
                status={"video"}
                updatePermission={updatePermission}
                permissionStatus={state.videoPermission}
              />
              <AudioDetector
                status={"audio"}
                audioStatus={state.audioStatus}
                updatePermission={updatePermission}
                permissionStatus={state.audioPermission}
              />
            </div>
            <div className="option-container oc-response">
              <section>
                {state.audioStatus && state.audioPermission !== "error" ? (
                  <IoMicSharp
                    className="option-container-svg"
                    style={{ color: "green" }}
                    onClick={() =>
                      updateState("audioStatus", !state.audioStatus)
                    }
                  />
                ) : (
                  <IoMicOffSharp
                    className="option-container-svg"
                    style={{ color: "red" }}
                    onClick={() =>
                      updateState("audioStatus", !state.audioStatus)
                    }
                  />
                )}
                {state.videoStatus && state.videoPermission !== "error" ? (
                  <FiVideo
                    className="option-container-svg"
                    style={{ color: "green" }}
                    onClick={() =>
                      updateState("videoStatus", !state.videoStatus)
                    }
                  />
                ) : (
                  <FiVideoOff
                    className="option-container-svg"
                    style={{ color: "red" }}
                    onClick={() =>
                      updateState("videoStatus", !state.videoStatus)
                    }
                  />
                )}
                <button onClick={(e) => joinMeeting(e)} className="btn">
                  <SiGooglemeet /> Join Meeting
                </button>
              </section>
            </div>
          </div>
        ) : (
          <>
            <div className="image-container">
              <img src={allow} alt="img" />
            </div>
            <div className="options">
              <h3 style={{ fontSize: "20px", width: "auto" }}>
                Do you want people to see and hear you in the meeting ?
              </h3>
              <p>
                {" "}
                You can turn off your microphone and camera anytime you want
              </p>
              <div className="user-options">
                <button onClick={() => meetingStatus("video", false)}>
                  <AiOutlineCamera className="svg-icons" /> Join with Video
                </button>
                <button onClick={() => meetingStatus("audio", false)}>
                  <MdOutlineKeyboardVoice className="svg-icons" /> Join with
                  Audio
                </button>
                <button onClick={() => meetingStatus("both", false)}>
                  <BiUserCircle className="svg-icons" /> Join with Audio and
                  Video
                </button>
              </div>

              <p
                onClick={() => meetingStatus("not-need", false)}
                style={{
                  marginTop: "1rem",
                  color: "brown",
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                Continue without audio and camera
              </p>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
}
