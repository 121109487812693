import React from 'react'
import { Paper } from '@material-ui/core'

function MeetingFinished() {
  return (
    <Paper>
    <div
      style={{
        width:"100vw",
        height:"100vh",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        flexDirection:"column",
      }}
     >  
        <p 
          style={{
            color:"#d92267",
            fontSize:"xx-large",
            fontWeight:"bold",
            // padding:"1rem",
            textAlign:"center"
          }}
        >
          You Left the Meeting
        </p>
        <center style={{
          fontSize:"xx-large",
          // fontWeight:"bold"
        }}>Please visit the Yuukke</center>
    </div>
  </Paper>

  )
}

export default MeetingFinished
