import { Paper } from '@material-ui/core'
import React from 'react'
import { RotatingLines } from 'react-loader-spinner'
import { ErrorMessage } from '../constants'

function Error({message, showOnlyMessage=false, status=""}) {

 


  return (
    // <div>{ErrorMessage}</div>
    <Paper>
      <div
        style={{
          width:"100vw",
          height:"100vh",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          flexDirection:"column",
          gap:"0.5rem"
        }}
       >  
          { showOnlyMessage && status === "PENDING" &&
            <RotatingLines
              strokeColor="#d92267"
              strokeWidth="5"
              animationDuration="0.75"
              width="124"
              visible={true}
            />
          }
          <p 
            style={{
              color:"#d92267",
              fontSize:"xx-large",
              fontWeight:"bold",
              // padding:"1rem",
              textAlign:"center"
            }}
          >
            {message}
          </p>
          {!showOnlyMessage && <center style={{
            fontSize:"xx-large",
            // fontWeight:"bold"
          }}>Please visit the Yuukke</center>}
      </div>
    </Paper>
    
  )
}

export default Error