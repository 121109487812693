import React from "react";
import { Modal } from "react-bootstrap";
import Loading from "../src/images/loader.gif";
function FullPageLoading() {
  return (
    <div style={{ border: "none" }} className="app-loader">
      {/* <Modal
        show="true"
        style={{
          border: "none",
          background: "black",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Modal.Body
          style={{
            background: "black",
            margin: "0 auto",
            border: "unset",
            border: "none",
          }}
        > */}
      <img src={Loading} alt="loading" style={{ background: "black" }} />
      {/* <img src="/loading.gif" alt="loading" /> */}
      {/* </Modal.Body>
      </Modal> */}
    </div>
  );
}

export default FullPageLoading;
